import {useState} from "react";
import {Popup} from "../pages/Root";
import ImageGallery from "react-image-gallery";

const images = [
	{
		original: "images/VeteransYes.jpg"
	},
	{
		original: "images/yesCA1.jpg"
	},
	{
		original: "images/yesCA2.jpg"
	},
];

export const Information = () => {
    const [ show, setShow ] = useState(true);

    return (
	    <Popup inShow={show}>
		    <div className={"flex flex-col items-center w-full overflow-x-hidden overflow-y-auto border-b-2"}>
			    <img
				    className={"w-[360px] md:w-[480px] max-w-full px-4 md:px-12 border-b pb-2"}
				    src={"/FinalLogo.png"}
			    />
			    <h3 className={"text-3xl w-full flex flex-col md:flex-row items-center justify-center border-b uppercase p-2"}>
				    <p className={"text-blue-800"}>Voting</p>
				    <p className={"text-orange-600 pl-2"}>Information</p>
			    </h3>
			    <ul className={"list-disc w-full items-center py-2 px-4 gap-2"}>
				    <p className={"text-3xl pb-2 w-full flex justify-center"}>Let's Go Vote!</p>
				    <li>
					    Be sure to take advantage of your <b>Right to Vote</b> this <b>Election Day, Tuesday, November 5th</b> from <b>7:00 A.M. to 7:00 P.M.</b>
				    </li>
				    <li className={"w-full"}>
					    <b>Same-day registration and voting</b> is available on Election Day!
			        </li>
				    <li className={"w-full"}>
					    <a className={"text-blue-800 underline pl-1"} target={"_blank"}
					       href={"https://www.sandovalcountynm.gov/countyclerk/bureau-of-elections/elections/voting-options/all-polling-sites/"}
					       rel="noreferrer">
						    Check here for your Voting-Day locations in Sandoval County.
					    </a>
				    </li>
			    </ul>
			    <div className={"border-2 max-w-[360px] md:w-[480px] mx-2"}>
				    <ImageGallery items={images}/>
			    </div>
		    </div>
		    <button
			    onClick={() => setShow(false)}
			    className={"border-2 mt-3 border-black/50 text-black text-lg px-4 py-1 rounded disabled:text-gray-400 disabled:border-gray-300"}>
			    To LukeForNM
		    </button>
	    </Popup>
    );
};
